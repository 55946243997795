import React, { useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { onCheckRender, onOCP, onUpdateName } from '../../actions/contentAction';
import videoThumb from '../../images/fav-new.png';
import PublishModal from "../project/PublishModal"
import OcpCopyModal from './OcpCopyModal';
import { useSelector } from 'react-redux';

let renderInterval = false
const VideoListCard = ({ curElem, handleDelete, fetchVideos }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [nameLoader, setNameLoader] = useState(false)
    const [show, setShow] = useState(false)
    const [memberShip, setMemberShip] = useState([])


    const [state, setState] = useState({
        cardStatus: curElem.status,
        render: "",
    })
    const [ocp, setOcp] = useState({
        loader: false,
        data: "",
        show: false
    })
    const [nameData, setNameData] = useState({
        cName: "",
        cId: "",
        active: false,
        loader: false
    })

    const handleSubmit = () => {
        if (curElem.name !== nameData.cName) {
            let data, epName
            epName = "update-video"
            data = {
                videoName: nameData.cName,
                videoId: nameData.cId
            }
            setNameLoader(true)
            dispatch(onUpdateName(epName, data, fetchVideos, setNameLoader, nameData, setNameData))
        } else {
            setNameData({
                ...nameData,
                cName: "",
                cId: "",
                active: false,
            })
        }
    }

    const handleChange = (e) => {
        setNameData({
            ...nameData,
            cName: e.target.value
        })
    }

    const handleShow = (data) => {
        setNameData({
            ...nameData,
            cName: data.name,
            cId: data.id,
            active: true
        })
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    // 0 => Script Approval Pending
    // 1 => Slide Generated
    // 2 => Video Rendered 
    // 3 => Video Failed Rendering
    // 4 => Awaiting Customization
    // 5 => Downloading Resources
    // 6 => Rendering
    // 7 => Waiting in Local Queue
    // 9 => Added to Queue


    const onRenderCheck = () => {
        let data = {
            type: "video",
            id: curElem.id
        }
        dispatch(onCheckRender(data, renderInterval, fetchVideos, state, setState))
    }

    const handleOCP = (id) => {
        setOcp({
            ...ocp,
            loader: true
        })
        dispatch(onOCP(id, ocp, setOcp))
    }

    useEffect(() => {
        if (curElem.status === "6" || curElem.status === "5" || curElem.status === "7" || curElem.status === "9") {
            renderInterval = setInterval(() => {
                onRenderCheck()
            }, 3000)
        }

        return () => {
            clearInterval(renderInterval)
            renderInterval = false
        }
    }, [])

    useEffect(() => {
        setState({
            ...state,
            cardStatus: curElem.status,
        })
    }, [curElem])


    return (
        <div className="col-xl-3 col-md-6 col-sm-12">
            <div className="videoProject-single alt">
                <div className="videoProject-single-top" >
                    <div className={`video-card-wrapper-1 dashboard-card-bg ${state.cardStatus === "3" ? "dashboard-card-bg-2" : ""} p-0`} >
                        {state.cardStatus === "2" ? <img alt='' className="mb-3 vidThumb-img  img-fluid" style={{ height: "100%", width: "100%", }} src={curElem.thumbnail !== "" ? curElem.thumbnail : ""} /> : ""}
                        <div className="video-card-content">

                            <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} alt="" />

                            <p className={`font-italic mb-0 text-light ${state.cardStatus === "5" ? "mt-4" : ""}`} style={{ fontWeight: " 600", fontSize: "15px", textAlign: "center" }}>
                                {state.cardStatus === "0" ? "Script Approval Pending" :
                                    state.cardStatus === "1" ? "Slide Generated" :
                                        state.cardStatus === "3" ? "Video Failed Rendering" :
                                            state.cardStatus === "4" ? "Awaiting Customization" :
                                                state.cardStatus === "5" ? "Downloading Resources" :
                                                    state.cardStatus === "7" ? "Waiting in  Queue" :
                                                        state.cardStatus === "9" ? "Added to Queue" : ""}
                            </p>
                            {
                                state.cardStatus === "5" ?
                                    <div className="render_wrapper mt-3" style={{ width: "100%" }}>
                                        <ProgressBar className='bg-warning ' variant="warning" animated now={100} />
                                    </div> : ""
                            }
                            {state.cardStatus === "6" ?
                                <>
                                    <div className="render_text mt-4 d-flex justify-content-between" style={{ width: "100%" }}>
                                        <p style={{ color: '#ffff', fontWeight: "bold", fontSize: "15px" }}>Rendering progress</p>
                                        <p>{state.render}%</p>
                                    </div>
                                    <div className="render_wrapper mt-3" style={{ width: "100%" }}>
                                        <ProgressBar className='bg-warning ' variant="warning" animated now={state.render} />
                                    </div>
                                </>
                                : ""}

                        </div>
                    </div>
                    <div className="vidHover">
                        <div><p>{curElem.created}</p></div>
                        <div className="vidiconAll">
                            <div className="vidHover-single">
                                <Link to={`${curElem.status === "0" ? `/content-set?videoId=${curElem.id}` : `/editor?id=${curElem.id}`}`}>
                                    <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                    <span>Edit</span>
                                </Link>
                            </div>
                            {curElem.status === "2" ?
                                <>
                                    <div className="vidHover-single">
                                        <a href={curElem.videoUrl}>
                                            <span className="vidHover-icon"><i class="fa fa-arrow-circle-o-down" aria-hidden="true"></i></span>
                                            <span>Download</span>
                                        </a>
                                    </div>
                                    <div className="vidHover-single">
                                        <a onClick={() => setShow(true)} className="cursor-pointer">
                                            <span className="vidHover-icon"><i class="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                            <span>Publish</span>
                                        </a>
                                    </div>
                                    {
                                        memberShip.includes("enterprise") || memberShip.includes("bundle") ?
                                            <div className="vidHover-single">
                                                <a onClick={() => handleOCP(curElem.id)} className="cursor-pointer">
                                                    <span className="vidHover-icon">
                                                        {ocp.loader ?
                                                            <i className="fa fa-spinner fa-spin" />
                                                            :
                                                            <i class="fa fa-desktop" aria-hidden="true"></i>
                                                        }

                                                    </span>
                                                    <span>OCP</span>
                                                </a>
                                            </div>
                                            : ''
                                    }

                                </> : ""}
                            <div className="vidHover-single">
                                <Link onClick={() => handleDelete(curElem)}>
                                    <span className="vidHover-icon"><i class="fa fa-trash" aria-hidden="true"></i></span>
                                    <span>Delete</span>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="videoProject-single-bottom">
                    <div className="videoProject-single-edIcon cursor-pointer" onClick={() => handleShow(curElem)} >
                        {nameLoader ? <i className="fa fa-spinner fa-spin" /> : <i class="fa fa-pencil-square-o" aria-hidden="true" />}

                    </div>
                    <div className="videoProject-single-edName text-light">
                        <span>
                        {nameData.active ? <>
                            <input
                                value={nameData.cName}
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleSubmit(e)}
                            />
                        </> :
                            curElem.name}
                        </span>
                    </div>
                </div>
            </div>
            <PublishModal
                curElem={curElem}
                show={show}
                setShow={setShow}
                memberShip={memberShip}
            />

            <OcpCopyModal
                setOcp={setOcp}
                ocp={ocp}
            />
        </div>
    )
}

export default VideoListCard