import React, { useState } from 'react';
import { FiTrash2 } from "react-icons/fi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from 'react-redux';
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import { BiMicrophoneOff } from 'react-icons/bi'
import { BiMicrophone } from 'react-icons/bi'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import loginGg from '../../../images/login-bg.jpg'
import { ReactComponent as TransitionIcon } from '../../../images/transition.svg'
import { deleteTile, onAddSlide, rearrangeTiles, removeTtsFromSlides, updateScript } from '../../../actions/contentAction';
import { AiFillPlusCircle } from "react-icons/ai"



const VideoTiles = ({ slideData }) => {

  const dispatch = useDispatch()
  const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
  const [button, setButton] = useState('Delete');
  const [showDelete, setShowDelete] = useState(false);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    slide: false,
    index: -1
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };




  const onConfirm = () => {
    handleDelete();
    setButton('Deleting...');
  }

  const onCancel = () => {
    setShowDelete(false);
  }
  const onSetRecorder = (result) => {

    if (!result.destination) {
      return;
    }
    const items = reorder(slideData, result.source.index, result.destination.index);
    dispatch(rearrangeTiles(items));
  }

  const onSelectTile = (index) => {
    if (index !== selectedSlideIndex) {
      dispatch(updateScript(index, selectedSlideIndex))
    }
  }

  const handleDeleteAlert = (e, slide, index) => {
    e.stopPropagation()
    setState({
      ...state,
      slide: slide,
      index: index
    })
    setShowDelete(true)
  }

  const handleDelete = () => {
    if (slideData.length === 1) {
      swal('Oops..', 'You cannot delete last slide')
    } else {
      dispatch(deleteTile(state.slide, state.index, setShowDelete, setButton))
    }
  }

  const handleTts = (slideIndex, e) => {
    e.stopPropagation()
    dispatch(removeTtsFromSlides(slideIndex))
  }

  const handleAddSlide = (data) => {
    let obj = {
      videoId: data.videoId,
    }
    setLoader(true)
    dispatch(onAddSlide(obj, setLoader))
  }

  return (

    <>
      <div className="editorReelwrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="editorReel-scroll">
                <DragDropContext onDragEnd={onSetRecorder}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                      <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {
                          slideData.length > 0 ?
                            slideData.map((curElem, index) => {
                              return (
                                <>
                                  <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                    {(provided) => (
                                      <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={index}
                                        onClick={() => onSelectTile(index)}
                                        className={curElem.isSelected === "1" ? 'selected-tile' : 'selected-tile-hover'}
                                      >
                                        <div
                                          className="editorReel-single "
                                          style={curElem.isSelected === "1" ? { border: 'none', } : {}}
                                        >
                                          {curElem.videoTiles.type !== "color" ?
                                            <img
                                              src={curElem.videoTiles.thumbnail ? curElem.videoTiles.thumbnail : loginGg}
                                              alt='tiles-poster'
                                              style={{ width: 'auto', height: '100%', cursor: 'pointer' }}
                                            />
                                            :
                                            <div
                                              alt='tiles-poster'
                                              style={{ width: '100%', height: '100%', cursor: 'pointer', background: curElem.videoTiles.thumbnail }}
                                            />
                                          }

                                          <FiTrash2
                                            className="delIcon"
                                            color='#fff'
                                            style={
                                              {
                                                cursor: 'pointer',
                                                color: `${curElem.isSelected === "1" ? '#E46FFA' : ''}`,
                                              }
                                            }
                                            onClick={(e) => handleDeleteAlert(e, curElem, index)}
                                          />
                                          {curElem.audio.enable ?
                                            <OverlayTrigger

                                              placement={'top'}
                                              overlay={
                                                <Tooltip id="tooltip-top" className='tooltipsarrow' >
                                                  TTS Active
                                                </Tooltip>
                                              }

                                            >
                                              <div>
                                                <BiMicrophone
                                                  className="voiceIcn"
                                                  style={{ cursor: 'pointer', color: `${curElem.isSelected === "1" ? '#E46FFA' : ''}` }}
                                                  onClick={(e) => handleTts(index, e)}
                                                />
                                              </div>
                                            </OverlayTrigger>
                                            :
                                            <OverlayTrigger
                                              placement={'top'}
                                              overlay={
                                                <Tooltip id="tooltip-top" className='tooltipsarrow'>
                                                  TTS Inactive
                                                </Tooltip>
                                              }
                                            >
                                              <div>
                                                <BiMicrophoneOff
                                                  className="voiceIcn-off"
                                                  style={{ cursor: 'pointer', color: `${curElem.isSelected === "1" ? '#E46FFA' : ''}` }}
                                                />
                                              </div>
                                            </OverlayTrigger>
                                          }
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                  {curElem.transitionName !== "" && curElem.transitionName ?
                                    <div className="transition-wrapper" title={curElem.transitionName}>
                                      <svg width="22px" height="22px" viewBox="0 0 22 22" >
                                        <TransitionIcon style={{ color: '#fff' }} />
                                      </svg>
                                    </div> : ""}
                                  <div className="addReelBt" onClick={() => handleAddSlide(curElem)}>
                                    {loader ? <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#4F94C9", fontSize: '25px' }} /> :
                                      <AiFillPlusCircle />}
                                  </div>
                                </>
                              )
                            }) : ''
                        }
                        {provided.placeholder}

                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>


                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText={button}
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="light"
                  title="Are you sure?"
                  onConfirm={(e) => onConfirm(e)}
                  onCancel={(e) => onCancel(e)}
                  focusCancelBtn
                  show={showDelete}
                >
                  Do you want to delete this slide
                </SweetAlert>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};




export default VideoTiles;
