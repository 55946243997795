import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import { onEditScript, onHandleContent, onScriptFetch } from "../../actions/contentAction";
import { BsArrowLeft } from "react-icons/bs";

const ContentSet = ({ location }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const createdCamp = useSelector(state => state.content.createdCamp)
    const { videoId } = queryString.parse(location.search)
    const [slideLen, setSlideLen] = useState(0)
    const [state, setState] = useState({
        script: "",
        tCode: ""
    })
    const [loader, setLoader] = useState({
        transLoader: false,
        saveLoader: false,
        generateLoader: false
    })

    const fetchEditorData = () => {
        dispatch(onScriptFetch(videoId, history, state, setState))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
        if (name === "script") {
            dispatch(onEditScript(value))
        }
    }

    const handleScript = (type) => {
        let data = {
            type: type,
            script: state.script,
            videoId: videoId
        }
        if (type === "save") {
            setLoader({
                ...loader,
                saveLoader: true
            })
        }
        else if (type === "generate-video") {
            setLoader({
                ...loader,
                generateLoader: true
            })
        }
        dispatch(onHandleContent(data, history, loader, setLoader))

    }

    useEffect(() => {
        let arr = state.script.split(".")
        if (arr[arr.length - 1] === "") {
            setSlideLen(arr.length - 1)
        }
        else {
            setSlideLen(arr.length)
        }
    }, [state.script])

   
    useEffect(() => {
        fetchEditorData()
    }, [videoId])

    return (
        <>
            <TitleBar title="Video Sets" />

            <Navbar />
            <section className="siteWrap">
                <div className="contWrap">
                    <div className="container">
                        <div className="contWrap-title justify-content-between">
                            <Link to={`/video-list`} style={{ color: "white", textTransform: "capitalize" }}>
                                <h2><BsArrowLeft /> {createdCamp.campaignName}<span>|</span> {createdCamp.videoSetName} <span>|</span> {createdCamp.keyword} </h2>
                            </Link>
                            <div>
                                <button
                                    className="demoLink"
                                    onClick={() => handleScript("save")}
                                >
                                    {
                                        loader.saveLoader ?
                                            <>
                                                Saving Script <i className="fa fa-spinner fa-spin" />
                                            </> : "Save Script"}
                                </button>
                                <button
                                    className="demoLink"
                                    onClick={() => handleScript("generate-video")}
                                    disabled={loader.generateLoader}
                                >
                                    {loader.generateLoader ?
                                        <>
                                            Converting to Video <i className="fa fa-spinner fa-spin" />
                                        </>
                                        :
                                        "Convert to Video"
                                    }

                                </button>
                            </div>
                        </div>
                        <div className="contWrap-list">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="contWrap-email">
                                        <div className="contWrap-email-in">
                                            <div className="email-block">
                                                <div className="email-block-top">
                                                    <p>To separate slides use <span style={{fontSize : "20px"}}>(. Dot)</span></p>
                                                </div>
                                                <textarea
                                                    className="contText"
                                                    name="script"
                                                    value={state.script}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                <div className="email-block-bottom">
                                                    <p>Slides : {slideLen}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ContentSet;