import axios from "axios";
import { commonAxios } from "../global/CommonAxios";
import {baseURL} from "../global/global";
import {setAlert} from "./alert";

export const addSocialAccounts = (data) => (dispatch) => {
    dispatch({type: 'ADD_SOCIAL_ACCOUNTS', payload: data})
}

export const fetchSocialAccounts = () => (dispatch,getState) => {
    commonAxios("fetch-social-accounts", {}, dispatch, getState().auth.token)
    .then((res) => {
        if(res.status){
            dispatch(addSocialAccounts(res.data));
        }
    }).catch((err) => {
        console.log(err)
    })
}


export const saveFacebook = (data) => async (dispatch, getState) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    };
    const body = JSON.stringify(data);

    try{
        const res = await axios.post(`${baseURL}save-facebook`,body,config);
        if(res.data.status === true){
            dispatch(setAlert(res.data.message,"success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,"danger"));
        }
    }catch(err){
        console.log(err);
    }
}