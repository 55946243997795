import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';

import iconUplod from '../../images/icon-upload.svg';
import iconWrite from '../../images/icon-write.svg';
import iconMic from '../../images/icon-mic.svg';
import iconRight from '../../images/icon-arrow.svg';

import modalCloseIcon from '../../images/modal-close.png';
import CreateCourseModal from "../createContent/ContentModal";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";

const DashboardHead = ({ campLength }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrandData = useSelector(state => state.rebrand.data);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    const handleShow2 = () => {
        if (memberShip.length === 1 && memberShip[0] === "premium") {
            if (campLength >= 100) {
                dispatch(setAlert("You can create 100 maximum projects. To create more please upgrade your membership.", "warning"))
            }else{
                setShow2(true);
            }
        } else {
            setShow2(true);
        }
    }

    return (
        <>
            <section className="hero-banner">
                <div className="hero-video">
                    {/* <iframe src="https://player.vimeo.com/video/428018128?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
                    <iframe src="https://player.vimeo.com/video/780632743?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="app intro video"></iframe>

                </div>
                <div className="hero-banner-txt">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="hero-banner-left">
                                    <h2>WELCOME TO</h2>
                                    <h3>{rebrandData ? rebrandData.name : "CONTENTREEL"}</h3>
                                    <p>Instantly create impactful and engaging content videos for your YouTube channel, TikTok, Instagram Reels and everything in between. Create all shapes and sizes of videos and publish them everyday, dominating your niche.</p>
                                    <a onClick={handleShow} className="demoLink"><i class="fa fa-play" aria-hidden="true"></i> Watch Demo Video</a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="hero-banner-right">
                                    {auth.user.is_client_account !== "1" ?
                                        <a onClick={handleShow2} className="bannerLink">
                                            <div className="bannerLink-left"><img src={iconWrite} alt="" />create project</div>
                                            <div className="bannerLink-right"><img src={iconRight} alt="" /></div>
                                        </a> : ""}
                                    <Link to="/projects" className="bannerLink">
                                        <div className="bannerLink-left"><img src={iconUplod} alt="" />VIEW projects</div>
                                        <div className="bannerLink-right"><img src={iconRight} alt="" /></div>
                                    </Link>
                                    {auth.user.is_client_account !== "1" ?
                                        <Link to="/integration" className="bannerLink">
                                            <div className="bannerLink-left"><img src={iconMic} alt="" />INTEGRATIONS</div>
                                            <div className="bannerLink-right"><img src={iconRight} alt="" /></div>
                                        </Link> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Modal className="VideoModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe src="https://player.vimeo.com/video/782035442?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false" allowfullscreen="" allow="autoplay,fullscreen,picture-in-picture" title="Player for CourseReel Intro" data-ready="true" tabindex="-1"></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <CreateCourseModal
                show={show2}
                handleClose={handleClose2}
            />
        </>
    )
}

export default DashboardHead;